import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import { regionContent, RegionId } from "../data/appData";
import { regionReports } from "../data/reportData";

type ContentBlock =
  | { type: "text"; body: string }
  | { type: "image"; src: string; alt: string }
  | { type: "video"; src: string; poster?: string }
  | { type: "table"; headers: string[]; rows: string[][] };

type Section = {
  title: string;
  sectionIndex?: number;
  content: ContentBlock[];
};

type Quote = {
  text: string;
  name: string;
  image: string;
  sectionIndex?: number;
};

type SectionItem = { type: "section"; content: Section };
type QuoteItem = { type: "quote"; content: Quote };

type ContentItem = SectionItem | QuoteItem;

const ReportDetail: React.FC = () => {
  const { region, reportId } = useParams<{ region: string; reportId: string }>();
  const [openFAQIndex, setOpenFAQIndex] = useState<number | null>(null);

  if (!region || !(region in regionContent)) {
    return <div>Region not found</div>;
  }

  const validRegion = region as RegionId;
  const reportsForRegion = regionReports[validRegion];
  const report = reportsForRegion.find((r) => r.id === reportId);

  if (!report) {
    return <div>Report not found</div>;
  }

  const { sections, quotes = [], faq } = report;

  const combinedContent: ContentItem[] = [
    ...sections.map((section) => ({
      type: "section" as const,
      content: section,
    })),
    ...quotes.map((quote) => ({
      type: "quote" as const,
      content: quote,
    })),
  ].sort((a, b) => {
    const indexA = a.content.sectionIndex ?? Number.MAX_VALUE;
    const indexB = b.content.sectionIndex ?? Number.MAX_VALUE;
    return indexA - indexB;
  });

  const toggleFAQ = (index: number) => {
    setOpenFAQIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div>
      <div className="header">
        <img alt="MyBrunel Logo" width="100" height="26" src="/images/brunel_logo.svg" />
      </div>
      <div className="description-container">
        <div className="button-container">
          <Link to={`/dashboard/${validRegion}`} className="button">Back</Link>
        </div>
        <div className="report-detail">
          {/* Report Main Information */}
          <img src={report.imageSrc} alt={report.title} className="report-detail-image" />
          <h1>{report.title}</h1>
          <h2>{report.subtitle}</h2>
          <div className="report-intro" dangerouslySetInnerHTML={{ __html: report.intro }}></div>
          <div className="report-intro" dangerouslySetInnerHTML={{ __html: report.mainGoal }}></div>
          <div className="report-intro" dangerouslySetInnerHTML={{ __html: report.audience }}></div>

          {/* Render Sections and Quotes */}
          {combinedContent.map((item, index) =>
            item.type === "section" ? (
              <div key={`section-${index}`} className="report-section">
                <h3>{item.content.title}</h3>
                {item.content.content.map((block, blockIndex) => {
                  if (block.type === "text") {
                    return (
                      <p key={`text-${blockIndex}`}>
                        {block.body.split("\n").map((line, lineIndex) => (
                          <React.Fragment key={lineIndex}>
                            {line}
                            {lineIndex < block.body.split("\n").length - 1 && <br />}
                          </React.Fragment>
                        ))}
                      </p>
                    );
                  } else if (block.type === "image") {
                    return (
                      <div key={`image-${blockIndex}`} className="report-image">
                        <img
                          src={block.src}
                          alt={block.alt}
                          style={{
                            width: "100%",
                            maxWidth: "600px",
                            margin: "10px 0",
                            borderRadius: "8px",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                          }}
                        />
                      </div>
                    );
                  } else if (block.type === "video") {
                    return (
                      <div key={`video-${blockIndex}`} className="report-video">
                        <video
                          src={block.src}
                          controls
                          poster={block.poster}
                          style={{ width: "100%", marginTop: "10px" }}
                        />
                      </div>
                    );
                  } else if (block.type === "table") {
                    return (
                      <div key={`table-${blockIndex}`} className="report-table">
                        <table style={{ width: "100%", borderCollapse: "collapse", marginTop: "10px" }}>
                          <thead>
                            <tr>
                              {block.headers.map((header, headerIndex) => (
                                <th
                                  key={`header-${headerIndex}`}
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    backgroundColor: "#f4f4f4",
                                    textAlign: "left",
                                  }}
                                >
                                  {header}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {block.rows.map((row, rowIndex) => (
                              <tr key={`row-${rowIndex}`}>
                                {row.map((cell, cellIndex) => (
                                  <td
                                    key={`cell-${cellIndex}`}
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "left",
                                    }}
                                  >
                                    {cell}
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            ) : (
              <div key={`quote-${index}`} className="report-quote">
                <blockquote>{item.content.text}</blockquote>
                <div className="quote-person">
                  <img
                    src={item.content.image}
                    alt={item.content.name}
                    className="quote-person-image"
                  />
                  <p>
                    <strong>{item.content.name}</strong>
                  </p>
                </div>
              </div>
            )
          )}

          {/* FAQs Section with Accordion */}
          <div className="report-faq">
          <h3>FAQ's</h3>
          <div className="faq-container">
            {faq.map((item, index) => (
              <div key={index} className="faq-item">
                <button
                  className="faq-question"
                  onClick={() => toggleFAQ(index)}
                  style={{
                    display: "block",
                    width: "100%",
                    textAlign: "left",
                    padding: "10px",
                    background: "#f9f9f9",
                    border: "1px solid #ddd",
                    cursor: "pointer",
                  }}
                >
                  {item.question}
                </button>
                {openFAQIndex === index && (
                  <div
                    className="faq-answer"
                    style={{
                      padding: "10px",
                      background: "#ffffff",
                      border: "1px solid #ddd",
                      borderTop: "none",
                    }}
                  >
                    <p>{item.answer}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

          {/* Embedded Chatbot */}
          {report.chatbot && (
            <div className="chatbot-embed-container">
              <h3>{report.chatbot}</h3>
            <iframe
              src="/chatbot"
              title="Chatbot"
              className="chatbot-iframe"
            ></iframe>
          </div>
          )}
        </div>
      </div>
      <div id="version">v{process.env.REACT_APP_VERSION}</div>
    </div>
  );
};

export default ReportDetail;
