import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { regions } from '../data/appData';
import { useMsal } from '@azure/msal-react';

const Home: React.FC = () => {
    const { instance } = useMsal(); // Get the MSAL instance

    useEffect(() => {
        instance.handleRedirectPromise()
            .then(response => {
                if (!response) {
                    instance.ssoSilent({ scopes: ["openid", "profile", "email"] })
                        .catch(() => instance.loginPopup())
                        .then(response => console.log('Login successful!', response))
                        .catch(error => console.error('Login failed!', error));
                }
            })
            .catch(error => console.error('Redirect handling failed!', error));
    }, [instance]);
    

    return (
        <div>
            <div className="header">
                <img alt="MyBrunel Logo" width="100" height="26" src="/images/brunel_logo.svg" />
            </div>
            <div className="description-container">
                <h1>Select your region</h1>
            </div>
            <div className="region-selector">
                <div className="region-grid">
                    {regions.map((region) => (
                        <Link key={region.id} to={`/dashboard/${region.id}`} className="region-item">
                            <h3>{region.name}</h3>
                        </Link>
                    ))}
                </div>
            </div>
            
            <div id="version">v{process.env.REACT_APP_VERSION}</div>
        </div>
    );
};

export default Home;
