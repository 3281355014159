import { RegionId } from "./appData";

export const regionReports: Record<
  RegionId,
  {
    id: string;
    title: string;
    subtitle: string;
    description: string;
    intro: string;
    mainGoal: string;
    audience: string;
    imageSrc: string;
    sections: {
        title: string;
        sectionIndex?: number; // Optional index for the full section
        content: (
          | { type: "text"; body: string }
          | { type: "image"; src: string; alt: string }
          | { type: "video"; src: string; poster?: string }
          | { type: "table"; headers: string[]; rows: string[][] }
        )[];
      }[];
    quotes?: {
      text: string;
      name: string;
      image: string;
      sectionIndex?: number; // Position relative to sections
    }[];
    faq: { question: string; answer: string }[];
    chatbot: string;
  }[]
> = {
  americas: [/*
    {
      id: "activity-report",
      title: "Activity Report",
      subtitle: "Exists since October 2024 | Available for all regions",
      description:
        "A report focused on showing the sales activities of the organization.",
      intro:
        "What is the Activity Report?The Activity Report is a management report, focused on showing the sales activities of the organisation: the number of phonecalls, appointments made and what the results was",
      mainGoal:
        "Main goal of the report: Give insights in- and steer on sales activities per team and per individual",
      audience: "Main audience: Management, sales consultants",
      imageSrc: "/images/activity-report.png",
      sections: [
        {
          title: "Which Report Do I Select?",
          sectionIndex: 3, 
          content: [
            {
              type: "text",
              body: "Different regions have various types of activity reports based on their specific needs. Examples:",
            },
            {
              type: "image",
              src: "/images/activity-report-detail.png",
              alt: "Example of report selection",
            },
            {
              type: "text",
              body: "Why? Different regions have differnt types of activity reports, often multiple, depending on their specific needs.",
            },
            {
                type: "text",
                body: "So, which one’s for me? Which one’s for you depends on the region you’re in, and what you’re looking for exactly:",
              },
            {
            type: "table",
            headers: ["Region", "Report Type", "Focus", "Audience"],
            rows: [
                ["Australasia", "Account", "Mostly on client activity", "Sales Consultants"],
                ["Australasia", "Recruitment", "Mostly on recruitment activity", "Recruiters"],
            ],
            },
          ],
        },
        {
            title: "Full Length Training Video (8 mins)",
            sectionIndex: 0,
            content: [
              {
                type: "text",
                body: "The creator of the report, Raimond, explains how to use and interpret the report in this training video.",
              },
              {
                type: "video",
                src: "/videos/full-training-video.mp4",
              },
            ],
          },
        {
          title: "Your First Use of the Report",
          sectionIndex: 1, 
          content: [
            {
              type: "text",
              body: `Follow these steps:\n
                1. Go to app.powerbi.com.\n
                2. Click on ‘Apps’ in the left navigation panel.\n
                3. Select your region (e.g., “Australasia”).\n
                4. Select the “DLH – Activity Report” from the left panel.\n
                5. Make sure your region is selected and click ‘Next’ on the top right.\n
                6. Select the applicable report for you.`,
            },
            {
              type: "video",
              src: "/videos/first-use-guide.mp4",
            },
          ],
        },
        {
            title: "Felix",
            sectionIndex: 5, 
            content: [
              {
                type: "text",
                body: `“The key to finding the right data in this report is knowing what you want to get out of it\n
                This is how Felix navigates the Activity Report”`,
              },
              {
                type: "video",
                src: "/videos/first-use-guide.mp4",
              },
            ],
          },
        {
          title: "Best Practices",
          sectionIndex: 4, 
          content: [
            {
              type: "text",
              body: `Advanced user advice:\n
                - Fletcher Tracy (Global Lead Mining): "I use the Activity Report at the start of every day to go through it with my team and determine what needs to be done."\n
                - Felix: "The key to finding the right data in this report is knowing what you want to get out of it."\n
                - Kaikai: "I mostly look at the details tab, as I often need more detailed navigation."`,
            },
          ],
        },
      ],
      quotes: [
        {
          text: "I consider the Activity Report as one of the most important reports our sales consultants use. It should be the first report you look at and the last before you go home.",
          name: "Tania Sinibaldi (Managing Director of Operations)",
          image: "/images/tania-sinibaldi.png",
          sectionIndex: 0, // After section 1
        },
        {
          text: "I use the Activity Report at the start of every day to go through it with my team and determine what needs to be done.",
          name: "Fletcher Tracy (Global Lead Mining)",
          image: "/images/fletcher-tracy.png",
          sectionIndex: 3, // After section 3
        },
      ],
      faq: [
        "Why am I not seeing my activity in the report?",
        "Why am I seeing different data when I compare to other reports?",
        "Why is the report not refreshed based on my filter settings?",
      ],
      chatbot: "Ask the chatbot if you have further questions.",
    },
  */],
  asia: [],
  australasia: [],
  be: [],
  dachcz: [],
  "europe-africa": [],
  "middle-east-india": [],
  nl: [
    {
      id: "kto-report",
      title: "Klanttevredenheidsonderzoek (KTO) Report",
      subtitle: "Bestaat sinds Januari 2025  | Beschikbaar voor Management in Nederland",
      description:
        "Dit rapport bevat de resultaten van een enquête die is verstuurd naar kandidaten die recentelijk een sollicitatiegesprek hebben gehad bij Brunel.",
      intro: `
        <h3>Wat is het KTO Report?</h3>
        <p>Dit rapport bevat de resultaten van een enquête die is verstuurd naar kandidaten die recentelijk een sollicitatiegesprek hebben gehad bij Brunel.</p>

        <p>De enquête bestaat uit zes vragen waarin kandidaten hun ervaring beoordelen op een schaal van 1 tot 10. De vragen richten zich op de voorbereiding van de recruiter, het verloop van het gesprek, de helderheid van de gemaakte afspraken, de waarschijnlijkheid dat de kandidaat Brunel zou aanbevelen (NPS), en eventuele suggesties of verbeterpunten. </p>
        <h3>NPS</h3>
        <p>In het KTO Report vind je het Net Promoter Score (NPS) terug, dit is een meetmethode om klantloyaliteit en tevredenheid te evalueren. Kandidaten beantwoorden de vraag: "Hoe waarschijnlijk is het dat je Brunel zou aanbevelen aan een vriend of collega?" op een schaal van 0 (zeer onwaarschijnlijk) tot 10 (zeer waarschijnlijk). Respondenten worden ingedeeld in drie groepen: Promoters (score 9-10, zouden Brunel aanbevelen), Passives (score 7-8, tevreden maar niet enthousiast) en Detractors (score 0-6, ontevreden). De NPS wordt berekend door het percentage Detractors af te trekken van het percentage Promoters, wat resulteert in een score tussen -100 en +100. Een hogere NPS duidt op sterkere loyaliteit en ook een hogere kans op het feit dat de kandidaat Brunel weer zou aanbeleven bij anderen. </p>
        <img src="/images/nps.jpg" alt="Voorbeeld afbeelding" style="max-width: 60%; height: auto; border-radius: 8px; margin-bottom: 20px;" />`,
      mainGoal: `
      <h3>Wat is het doel van het KTO Report?</h3>
      <p>Het doel van dit rapport is om inzicht te krijgen in de sollicitatie-ervaring van kandidaten bij Brunel. Door de feedback van kandidaten te analyseren, kunnen we verbeterpunten identificeren en onze sollicitatieprocedure optimaliseren. Dit helpt ons om een betere ervaring te bieden aan toekomstige kandidaten en onze reputatie als werkgever te versterken.</p>
        `,
      audience: `
      <h3>Voor wie is het KTO Report bedoeld?</h3>
      <p>Het is belangrijk om de sollicitatie-ervaring van kandidaten te evalueren omdat dit directe invloed heeft op ons vermogen om talent aan te trekken en te behouden. Positieve ervaringen kunnen leiden tot aanbevelingen en een sterkere reputatie, terwijl negatieve ervaringen ons kunnen schaden. Door actief feedback te verzamelen en te analyseren, kunnen we gerichte verbeteringen doorvoeren en onze processen continu verbeteren. Dit geldt specifiek voor het management, aangezien hun rol cruciaal is in het vormgeven en optimaliseren van deze ervaringen.</p>
        `,
      imageSrc: "/images/kto-report.png",
      sections: [
        {
            title: "Training Video (2 mins)",
            sectionIndex: 0,
            content: [
              {
                type: "text",
                body: "Astrid neemt je mee door het KTO Report.",
              },
              {
                type: "video",
                src: "/videos/KTO-Report.mp4",
                poster: "/images/kto-report.png"
              },
            ],
          },
        {
          title: "Je eerste gebruik van dit report",
          sectionIndex: 1, 
          content: [
            {
              type: "text",
              body: `1. Ga naar app.powerbi.com.\n
                2. Klik op 'Apps' in het linker navigatiepaneel.\n
                3. Selecteer jouw regio: Nederland.\n
                4. Selecteer het "KTO Report" uit het linker paneel.
                `,
            },
          ],
        },
        {
            title: "Report Sponsor",
            sectionIndex: 5, 
            content: [
              {
                type: "text",
                body: `De sponsor van het KTO Report is Astrid Haverkort. Heb je vragen over de opzet van het report of zou je graag iets toegevoegd/anders willen zien? Neem dan contact op met Astrid. \n
                Heb je problemen met het report, bijvoorbeeld omdat het niet werkt, of omdat je geen toegang hebt? Stuur dan een email naar support@brunel.net, voorzien van een volledig screenshot en duidelijke omschrijving van het probleem. `,
              },
            ],
          },
        {
          title: "Best Practices",
          sectionIndex: 4, 
          content: [
            {
              type: "text",
              body: `
                -	Het KTO Report ververst wekelijks op maandag. Wil je als eerste de nieuwe resultaten zien? Kijk dan op die dag, na 12.00, naar het report.\n
                - Er staan niet alleen cijfers in het report, ook staan er comments en toelichten van kandidaten in. Deze kunnen heel interessante inzichten leveren. Zoom dus zeker even in op individuele kandidaten voor meer informatie.\n
                - Je kan de data in dit report ook als Excel downloaden zodat je eventueel nog meer analyses kunt maken.`,
            },
          ],
        },
      ],
      quotes: [
        {
          text: "Het PBI-rapport over de sollicitatie-ervaring is van cruciaal belang voor ons. Het biedt ons waardevolle inzichten in hoe kandidaten hun sollicitatieproces bij Brunel ervaren. Door deze feedback te analyseren, kunnen we gerichte verbeteringen doorvoeren en onze sollicitatieprocedure optimaliseren. Dit helpt ons niet alleen om een betere ervaring te bieden aan toekomstige kandidaten, maar versterkt ook onze reputatie als werkgever. Het is essentieel dat we continu streven naar verbetering en dit rapport is een belangrijk instrument in dat proces.",
          name: "Xavier Hardy",
          image: "/images/xavier-hardy.png",
          sectionIndex: 0, // After section 1
        },
        {
          text: "Ik gebruik het KTO-rapport wekelijks om inzicht te krijgen in de tevredenheid van onze kandidaten. Het rapport helpt me om snel te zien waar we goed presteren en waar er ruimte is voor verbetering. Door de feedback van kandidaten te analyseren, kan ik gerichte acties ondernemen om onze wervingsprocessen te optimaliseren en de algehele ervaring van onze kandidaten te verbeteren. Het is een onmisbaar hulpmiddel in mijn werk als senior Online Recruitment Marketeer.",
          name: "Astrid Haverkort",
          image: "/images/astrid-haverkort.png",
          sectionIndex: 3, // After section 3
        },
      ],
      faq: [
        {
          question: "Er is ook een NPS dashboard. Hoe verschilt dit report van dat dashboard?",
          answer: "Het KTO Report omvat vragen die gesteld zijn aan kandidaten. Er is een apart tevredenheidsonderzoek voor klanten en specialisten die al bij Brunel in dienst zijn en deze data vind je terug in het NPS dashboard.",
        },
        {
          question: "Hoe vaak wordt het KTO Report bijgewerkt?",
          answer: "Het KTO Report wordt wekelijks bijgewerkt. Is de laatste verversdatum langer dan 8 dagen geleden? Stuur dan een bericht naar support@brunel.net.",
        },
        {
          question: "Een kandidaat heeft meerdere gesprekken in één week, hoeveel enquetes sturen we?",
          answer: "We sturen één survey uit over deze periode, om ervoor te zorgen dat de kandidaat niet teveel mails vanuit Brunel ontvangt.",
        },
      ],
      chatbot: "Gebruik de chatbot mocht je nog verdere vragen hebben.",
    },
  ],
};
