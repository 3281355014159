import React, { useEffect } from "react";

const ChatbotPage: React.FC = () => {
  useEffect(() => {
    // Load the chatbot script
    const script = document.createElement("script");
    script.src = "https://chatwidget-prod.web.app/embed/init.iife.js";
    script.type = "module";
    script.async = true;
    script.setAttribute("data-watermelon-widget-id", "6aZxglfpbz1");
    script.setAttribute("data-watermelon-settings-id", "12082");
    document.body.appendChild(script);

    // Cleanup on unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <h1>Chatbot</h1>
      <div id="chatbot-container"></div>
    </div>
  );
};

export default ChatbotPage;
